const { useState, useEffect } = wp.element;
import useFetch from '../hooks/useFetch';
import { FaStar } from 'react-icons/fa';
import { ProgressBar } from 'react-bootstrap';




const ReviewSummary = () => {
    const [ratings, setRatings] = useState([]);
    const businessId = '154543977265041';
    const apiKey = '53kclVUk3MrLpCIIXPkIkkmqdbPDTUIl';
    const {loading, data} = useFetch(`https://api.birdeye.com/resources/v1/review/businessid/${businessId}/summary?api_key=${apiKey}`);
    
    

    useEffect(() => {
        if(data.ratings){
            data.ratings.sort((a,b) => b.rating - a.rating);
          let newRating =  data.ratings.filter((rating) => rating.rating !== 0);
          setRatings(newRating);
        }
    }, [data])

    console.log(data);

    return (
        <div className="" style={{"margin-top":"30px" }}>
            <p style={{"fontSize":"18px"}}>Forest Lawn review summary</p>
            <h3 style={{"margin":"10px 0"}}><span style={{"font-size":"50px"}}>{data.avgRating}</span></h3>
            {ratings.length > 0 &&
                ratings.map((rating) => {
                    return (
                        <div className="row">
                            <div className="col-3">
                                <strong>{rating.rating}</strong> <FaStar style={{"color":"rgba(194, 147, 47, 1)","verticalAlign":"text-bottom"}} />
                            </div> 
                            <div className="col-6">
                                <p style={{ "padding":"7px 0"}}><ProgressBar style={{"verticalAlign":"text-bottom"}} variant="success" now={(rating.reviewCount/data.reviewCount)*100} /></p>
                            </div> 
                            <div className="col-3">
                                {rating.reviewCount}
                            </div>
                        </div>
                    )
                })
            }
            <hr style={{"margin-top":"20px"}}/>
            <div style={{"margin-top":"20px"}}>
                <p style={{"text-decoration": "underline"}}><strong>All Sources:</strong></p>
                {data.sources &&
                data.sources.map((source) => {
                    return (
                        <p>{source.sourceName} ({source.reviewCount})</p>
                    )
                })}
            </div>
            <hr style={{"margin-top":"20px"}}/>
        </div>
    );
};

export default ReviewSummary;
      