const { useState, useEffect } = wp.element;
import useFetch from '../hooks/useFetch';
import { FaStar } from 'react-icons/fa';
import "./review.css";
import { css } from "@emotion/react";
import DotLoader from "react-spinners/DotLoader";

const override = css`
  display: block;
  margin: 0 auto
`;

let arrayForHoldingReviews = [];


const reviewRating = {
    "background": "#385624",
    "border-radius": "20px",
    "padding": "10px 15px",
    "color": "#ffffff"
}

const ReviewList = () => {
    const [reviewsToShow, setReviewsToShow] = useState([]);
    const [reviewLoading, setReviewLoading] = useState(false);
    // const [page, setPage] = useState(1);
    const [next, setNext] = useState(10);
    const reviewsPerPage = 10;
    const businessId = '154543977265041';
    const apiKey = '53kclVUk3MrLpCIIXPkIkkmqdbPDTUIl';
    const {loading, data} = useFetch(`https://api.birdeye.com/resources/v1/review/businessid/${businessId}?api_key=${apiKey}`);
     
    // console.log(data);

    const loopWithSlice = (start, end) => {
        const slicedReviews = data.slice(start, end);
        arrayForHoldingReviews = [...arrayForHoldingReviews, ...slicedReviews];
        // console.log(arrayForHoldingReviews);
        setReviewsToShow(arrayForHoldingReviews);
      };

    function showMoreReviews(){
        setReviewLoading(true);
        // setPage(page + 1);
        loopWithSlice(next, next + reviewsPerPage);
        setNext(next + reviewsPerPage);
        setReviewLoading(false)
    }

    useEffect(() => {
        loopWithSlice(0, reviewsPerPage)
    }, [data])
    

    return (
        <div className="" style={{"margin-top":"30px" }}>
            <div className=''><DotLoader color={'#507336'} loading={loading} css={override} size={100} /></div>
            {loading ? <p className="text-center">Loading...</p> : ''}
            {
                reviewsToShow.map((review) => {
                    return (
                        <div className="review" style={{"padding":"10px 0"}}>
                            <div>
                                <p><span style={reviewRating}>{review.rating} <FaStar style={{"color":"rgba(194, 147, 47, 1)","verticalAlign":"text-bottom"}} /></span>
                                    <span style={{"margin": "0 5px"}}>On {review.sourceType}</span>
                                    <span>{review.reviewDate}</span>
                                </p>
                                <p style={{"margin":"10px auto"}}><strong>{review.reviewer.firstName}</strong></p>
                            </div>
                        <p style={{"margin-bottom":"30px"}}>{review.comments}</p>
                        <hr/>
                        </div>
                    )
                })
            }
            {loading ? '' : <button className="cta-button bg-umber white-font" onClick={showMoreReviews}>Show More</button>}
        </div>
    );
};

export default ReviewList;