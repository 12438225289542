const { useState } = wp.element;
import ReviewList from "./components/ReviewList";
import ReviewSummary from "./components/ReviewSummary";
// import useFetch from './hooks/useFetch';
// import { FaStar } from 'react-icons/fa';
// import './style.css';


const App = () => {
    // const businessId = '154543977265041';
    // const apiKey = '53kclVUk3MrLpCIIXPkIkkmqdbPDTUIl';
    // const {loading, data} = useFetch(`https://api.birdeye.com/resources/v1/review/businessid/${businessId}?count=10&api_key=${apiKey}`);
    // // const {loading, data} = useFetch(`https://api.birdeye.com/resources/v1/review/businessid/${businessId}/summary?api_key=${apiKey}`);
     
    // // console.log(reviews);
    // console.log(data);

    return (
        <div class="container">

            <div class="row">
                <div class="col-md-4">
                    <ReviewSummary />
                </div>
                <div class="col-md-8">
                    <ReviewList />
                </div>
            </div>
        </div>
    );
};

export default App;