const {useState, useEffect} = wp.element

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    setLoading(true)
    fetch(url, {
      headers: {
         'Accept': 'application/json'
      }
   })
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        setLoading(false)
        // console.log(data);
        })
  }, [url]);
  // console.log('im in useFetch');
  return {data, loading};
};

export default useFetch;
